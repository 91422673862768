import { StyledBackgroundImage, StyledSectionWrapper } from './BackgroundImage.styled';
import { ReactNode } from 'react';

type BackgroundImageProps = {
  bgColor: string;
  children: ReactNode | ReactNode[];
  opacity: number;
  size?: number;
  top?: number;
  left?: number;
  longBg?: boolean;
  disabled?: boolean;
};

export const BackgroundImage = ({
  bgColor,
  children,
  opacity,
  size = 850,
  top = 0,
  left = 0,
  longBg,
  disabled,
}: BackgroundImageProps) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <StyledSectionWrapper {...{ bgColor }}>
      <StyledBackgroundImage {...{ opacity, size, top, left, longBg }} />
      {children}
    </StyledSectionWrapper>
  );
};