export const GoogleIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 24"
                                     fill="none">
  <g clipPath="url(#clip0_2619_1179)">
    <path
      d="M23.5088 12.2261C23.5088 11.2429 23.4304 10.5253 23.2608 9.78125H12.2305V14.2192H18.705C18.5745 15.3221 17.8696 16.9831 16.3032 18.0992L16.2812 18.2478L19.7688 20.998L20.0104 21.0225C22.2295 18.9363 23.5088 15.8669 23.5088 12.2261Z"
      fill="#4285F4"/>
    <path
      d="M12.2296 23.9178C15.4016 23.9178 18.0645 22.8548 20.0095 21.0211L16.3023 18.0978C15.3102 18.8021 13.9787 19.2937 12.2296 19.2937C9.1228 19.2937 6.48598 17.2076 5.54603 14.3242L5.40825 14.3361L1.7818 17.193L1.73438 17.3272C3.6663 21.2337 7.63462 23.9178 12.2296 23.9178Z"
      fill="#34A853"/>
    <path
      d="M5.54719 14.3228C5.29917 13.5787 5.15564 12.7814 5.15564 11.9576C5.15564 11.1338 5.29917 10.3365 5.53414 9.59244L5.52757 9.43397L1.85568 6.53125L1.73554 6.58942C0.939305 8.21052 0.482422 10.0309 0.482422 11.9576C0.482422 13.8843 0.939305 15.7047 1.73554 17.3258L5.54719 14.3228Z"
      fill="#FBBC05"/>
    <path
      d="M12.2295 4.62403C14.4356 4.62403 15.9237 5.59401 16.7722 6.40461L20.0878 3.10928C18.0515 1.1826 15.4015 0 12.2295 0C7.63462 0 3.6663 2.68406 1.73438 6.59056L5.53297 9.59359C6.48597 6.7102 9.12279 4.62403 12.2295 4.62403Z"
      fill="#EB4335"/>
  </g>
  <defs>
    <clipPath id="clip0_2619_1179">
      <rect width="23.04" height="24" fill="white" transform="translate(0.480469)"/>
    </clipPath>
  </defs>
</svg>;