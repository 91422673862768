import React, { useMemo, useState } from 'react';
import { StyledEndIcon, StyledInput, StyledPlaceholder, StyledStartIcon, StyledWrapper, } from './AuthInput.styled';
import { useFormContext } from 'react-hook-form';
import { MailIcon } from '../../../UI/SVG/MailIcon';
import { LockIcon } from '../../../UI/SVG/LockIcon';
import { EyeIcon } from '../../../UI/SVG/EyeIcon';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type AuthInputProps = {
  fieldName: string;
};

export const AuthInput = ({ fieldName }: AuthInputProps) => {
  const { register, getValues, setValue, getFieldState } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const value = getValues(fieldName);
  const isError = !!getFieldState(fieldName)?.error;

  const inputType = useMemo(() => {
    switch (fieldName) {
      case 'email':
        return 'email';
      case 'password':
        return isPasswordVisible ? 'text' : 'password';
      case 'passwordConfirmation':
        return isPasswordVisible ? 'text' : 'password';
      case 'specialistPasscode':
        return isPasswordVisible ? 'text' : 'password';
      case 'newPassword':
        return isPasswordVisible ? 'text' : 'password';
      case 'confirmNewPassword':
        return isPasswordVisible ? 'text' : 'password';
      default:
        return 'text';
    }
  }, [fieldName, isPasswordVisible]);

  const startIcon = useMemo(() => {
    switch (fieldName) {
      case 'email':
        return <MailIcon/>;
      case 'bookingForSomeoneElseInfo.email':
        return <MailIcon/>;
      case 'password':
        return <LockIcon/>;
      case 'passwordConfirmation':
        return <LockIcon/>;
      case 'specialistPasscode':
        return <LockIcon/>;
      case 'newPassword':
        return <LockIcon/>;
      case 'confirmNewPassword':
        return <LockIcon/>;
      default:
        return null;
    }
  }, [fieldName]);

  const endIcon = useMemo(() => {
    switch (fieldName) {
      case 'password':
        return <EyeIcon/>;
      case 'passwordConfirmation':
        return <EyeIcon/>;
      case 'specialistPasscode':
        return <EyeIcon/>;
      case 'newPassword':
        return <EyeIcon/>;
      case 'confirmNewPassword':
        return <EyeIcon/>;
      default:
        return null;
    }
  }, [fieldName]);

  if (fieldName === 'phoneNumber') {
    return (
      <StyledWrapper {...{ isFocused, isError }} isFilled={!!value} id={`input-${fieldName}`}>
        {!value && !isFocused && (
          <StyledPlaceholder
            htmlFor={fieldName}
            hasStartIcon={!!startIcon}
            fieldName={fieldName}
          >
            <Content
              id={`login.signInWithEmail.${fieldName}`}
              type={TranslationTypesEnum.Main}
            />
          </StyledPlaceholder>
        )}
        <PhoneInput
          {...register(fieldName)}
          value={value}
          onChange={(value) => setValue(fieldName, value)}
          placeholder=""
          inputStyle={{
            border: 'none',
            outline: 'none',
            color: '#000',
            fontSize: '16px',
            fontWeight: '700',
            fontFamily: 'Apercu Pro',
          }}
          buttonStyle={{
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            scale: '1.3',
            marginBottom: '3px',
          }}
        />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper {...{ isFocused, isError }} isFilled={!!value} id={`input-${fieldName}`}>
      {!value && !isFocused && (
        <StyledPlaceholder htmlFor={fieldName} hasStartIcon={!!startIcon}>
          <Content
            id={`login.signInWithEmail.${fieldName}`}
            type={TranslationTypesEnum.Main}
          />
        </StyledPlaceholder>
      )}
      {startIcon && <StyledStartIcon>{startIcon}</StyledStartIcon>}
      <StyledInput
        {...register(fieldName)}
        id={fieldName}
        type={inputType}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {endIcon && (
        <StyledEndIcon
          onClick={() => setIsPasswordVisible((prevState) => !prevState)}
        >
          {endIcon}
        </StyledEndIcon>
      )}
    </StyledWrapper>
  );
};
