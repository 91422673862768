import { useMutation, UseMutationResult } from 'react-query';
import { functions } from '../../../firebase';
import { httpsCallable } from 'firebase/functions';
import { RegisterUserRequest, RegisterUserResponse } from '@vaza-eu/shoelessly';

export const useRegisterUserMutation = (): UseMutationResult<RegisterUserResponse, unknown, RegisterUserRequest> =>
  useMutation<RegisterUserResponse, unknown, RegisterUserRequest>(async (data: RegisterUserRequest) => {
    const cloudFn = httpsCallable<RegisterUserRequest, RegisterUserResponse>(functions, 'registerUser');
    const response = await cloudFn(data);
    return response.data;
  });