import styled, { css } from 'styled-components';

export const StyledSectionWrapper = styled.section<{ bgColor: string }>`
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  z-index: 1;
  overflow: hidden;
`;

export const StyledBackgroundImage = styled.div<{
  opacity: number,
  size: number,
  top: number,
  left: number,
  longBg?: boolean
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  bottom: 0;
  right: 0;
  background-image: url('/images/background.png');
  background-size: ${({ size }) => size}px;
  opacity: ${({ opacity }) => opacity};
  z-index: -10;

  ${({ longBg }) => longBg && css`
    background-image: url('/images/background-longer.png');
  `};
`;